module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","es"],"defaultLanguage":"en-US","i18nextOptions":{"fallbackLng":"en-US","supportedLngs":["en-US","es"],"defaultNS":"common","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["b","span"]},"pages":[{"matchPath":"/es/404/","excludeLanguages":["es"]},{"matchPath":"/es/404.html","excludeLanguages":["es"]},{"matchPath":"/es/dev-404-page/","excludeLanguages":["es"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Passport USA - Online Forms","short_name":"Passport USA - Online Forms","lang":"en-US","orientation":"any","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"./src/images/gov/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"32e7b0dd7f51d72ae694f74922b95d4f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
